<template>
  <v-card>
    <v-card-title>
      <DateRangePicker input-classes="mr-2 shrink" v-model="dateRange" />
      <DefaultModal
        @formSubmitted="formSubmitHandler"
        title="Dodawanie do licytacji"
        activator-name="Dodaj"
        ref="modal"
      >
        <AllProductsAutocomplete v-model="selectedProduct" />
      </DefaultModal>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Wyszukaj"
        class="shrink"
        single-line
        hide-details
        @keyup.enter="fetchItems"
        @blur="fetchItems"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="$data.$_paginationMixin_options"
      item-key="id"
      :loading="$data.$_paginationMixin_fetchingItems"
      :search="search"
      :items-per-page="$data.$_paginationMixin_itemsPerPage"
      :page="1"
      :server-items-length="$data.$_paginationMixin_totalCount"
      :footer-props="{
        'items-per-page-options': $data.$_paginationMixin_rowsPerPageOptions,
      }"
    >
      <template v-slot:item.product="{item}">
        {{ item.product.name }}
      </template>
      <template v-slot:item.sku="{item}">
        {{ item.product.sku }}
      </template>
      <template v-slot:item.category="{item}">
        {{ item.product.category.name }}
      </template>
      <template v-slot:item.binded="{item}">
        {{ item.product.binded ? 'Podbity' : 'Oczekuje' }}
      </template>
      <template v-slot:item.biddingPrice="{item}">
        {{ item.biddingPrice | currency(2, 4) }}
      </template>
      <template v-slot:item.biddingCount="{item}">
        {{ `${(item.biddingCount / 2) * 100}%` }}
      </template>
      <template v-slot:item.ceneoID="{item}">
        {{ item.product.id_Ceneo }}
      </template>
      <template v-slot:item.lastSuggesting="{item}">
        {{ item.product.lastSuggesting | date }}
      </template>
      <template v-slot:item.dateOfBid="{item}">
        {{ item.dateOfBid | date }}
      </template>
      <template v-slot:item.actions="{item}">
        <ButtonTooltip
          @click="unbidProduct(item)"
          :icon="item.product.binded ? 'mdi-refresh' : 'mdi-close'"
          :tooltip="item.product.binded ? 'Zeruj stawkę' : 'Usuń z listy'"
        />
      </template>
    </v-data-table>
    <Confirm ref="modalConfirm" />
  </v-card>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import DefaultModal from '@/components/Modals/DefaultModal'
import AllProductsAutocomplete from '@/components/UI/Autocompletes/AllProductsAutocomplete'
import paginationMixin from '@/mixins/pagination.mixin'
import DateRangePicker from '@/components/UI/DateRangePicker'
import RepositoryFactory from '@/repositories/RepositoryFactory'
import ButtonTooltip from '@/components/UI/ButtonTooltip'
import Confirm from '@/components/UI/Confirm'

const ProductRepository = RepositoryFactory.get('products')

export default {
  name: 'BiddingProducts',
  mixins: [paginationMixin],
  components: {
    DefaultModal,
    AllProductsAutocomplete,
    DateRangePicker,
    ButtonTooltip,
    Confirm,
  },
  data() {
    return {
      search: '',
      loading: false,
      selectedProduct: null,
      dateRange: null,
      headers: [
        {text: 'Nazwa produktów', value: 'product'},
        {text: 'SKU', value: 'sku'},
        {text: 'Nazwa kategorii', value: 'category'},
        {text: 'Status', value: 'binded'},
        {text: 'Stawka', value: 'biddingPrice'},
        {text: '% max. st.', value: 'biddingCount'},
        {text: 'Ost. sprzedaż', value: 'lastSuggesting'},
        {text: 'Ost. Licytacja', value: 'dateOfBid'},
        {text: '', value: 'actions', sortable: false},
      ],
    }
  },
  watch: {
    searchOptions: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      items: state => state.products.biddingProducts,
    }),
    searchOptions() {
      return {
        ...this.$data.$_paginationMixin_options,
        dateRange: this.dateRange,
      }
    },
  },
  methods: {
    ...mapActions(['fetchBiddingProducts', 'showNotification']),
    async fetchItems() {
      const {search, dateRange} = this
      this.$data.$_paginationMixin_fetchingItems = true
      const dRange = dateRange ? [...dateRange] : null
      const options = {
        dateBiddingFrom: dRange ? dRange.shift() : null,
        dateBiddingTo: dRange ? dRange.pop() : null,
      }
      const query = this.$_paginationMixin_createQuery(
        {
          ...this.$data.$_paginationMixin_options,
          search,
        },
        options
      )
      this.$data.$_paginationMixin_totalCount = await this.fetchBiddingProducts({
        ...query,
        ...options,
      })
      this.$data.$_paginationMixin_fetchingItems = false
    },
    async unbidProduct(item) {
      const {id, product} = item
      const modalTitle = product.binded ? 'Zerowanie stawki' : 'Usuwanie z listy licytacji'
      const modalMessage = product.binded
        ? `Czy na pewno chcesz wyzerować stawkę dla produktu "${product.name}"?`
        : `Czy na pewno chcesz usunąć produkt "${product.name}" z listy licytacji?`
      const res = await this.$refs.modalConfirm.open({
        title: modalTitle,
        message: modalMessage,
      })
      if (res) {
        try {
          await ProductRepository.unbidProduct([id])
          this.showNotification({
            message: 'NOTY_BIDDING_PRODUCTS_UNBIDDED',
            type: 'success',
          })
          await this.fetchItems()
        } catch (e) {
          this.showNotification({
            message: e.response.data,
            type: 'error',
          })
        }
      }
    },
    async formSubmitHandler() {
      let {selectedProduct} = this
      const payload = {
        idList: [selectedProduct],
      }
      try {
        await ProductRepository.bidProduct(payload)
        await this.fetchItems()
        this.$refs.modal.close()
        this.showNotification({
          message: 'NOTY_BIDDING_PRODUCT_BIDDED',
          type: 'success',
        })
        selectedProduct = null
      } catch (e) {
        this.showNotification({
          message: e.response.data,
          type: 'error',
        })
      }
    },
  },
}
</script>

<style scoped></style>
