var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('DateRangePicker',{attrs:{"input-classes":"mr-2 shrink"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('DefaultModal',{ref:"modal",attrs:{"title":"Dodawanie do licytacji","activator-name":"Dodaj"},on:{"formSubmitted":_vm.formSubmitHandler}},[_c('AllProductsAutocomplete',{model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}})],1),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchItems($event)},"blur":_vm.fetchItems},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.$data.$_paginationMixin_options,"item-key":"id","loading":_vm.$data.$_paginationMixin_fetchingItems,"search":_vm.search,"items-per-page":_vm.$data.$_paginationMixin_itemsPerPage,"page":1,"server-items-length":_vm.$data.$_paginationMixin_totalCount,"footer-props":{
      'items-per-page-options': _vm.$data.$_paginationMixin_rowsPerPageOptions,
    }},on:{"update:options":function($event){return _vm.$set(_vm.$data, "$_paginationMixin_options", $event)}},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.name)+" ")]}},{key:"item.sku",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.sku)+" ")]}},{key:"item.category",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.category.name)+" ")]}},{key:"item.binded",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.binded ? 'Podbity' : 'Oczekuje')+" ")]}},{key:"item.biddingPrice",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.biddingPrice,2, 4))+" ")]}},{key:"item.biddingCount",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s((((item.biddingCount / 2) * 100) + "%"))+" ")]}},{key:"item.ceneoID",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.id_Ceneo)+" ")]}},{key:"item.lastSuggesting",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.product.lastSuggesting))+" ")]}},{key:"item.dateOfBid",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.dateOfBid))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('ButtonTooltip',{attrs:{"icon":item.product.binded ? 'mdi-refresh' : 'mdi-close',"tooltip":item.product.binded ? 'Zeruj stawkę' : 'Usuń z listy'},on:{"click":function($event){return _vm.unbidProduct(item)}}})]}}])}),_c('Confirm',{ref:"modalConfirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }